<template>
  <div>
    <table class="table mt-3">
      <tbody>
        <tr v-for="(address, id) in addresses" :key="id">
          <td>{{ address.name ?? "" }}</td>
          <td>{{ address.street ?? "" }}</td>
          <td class="d-flex justify-content-end">
            <span class="material-icons me-5">
              <a
                href=""
                style="color: #00c0c9"
                @click="edit(id)"
                data-bs-toggle="modal"
                data-bs-target="#addressModal">
                edit
              </a>
            </span>
            <span class="material-icons">
              <a href="" style="color: #cf3900" @click.prevent="remove(id)">
                delete
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <button
        type="button"
        class="btn button mt-3"
        data-bs-toggle="modal"
        data-bs-target="#addressModal"
        @click="clear"
      >
        Adicionar Endereço
      </button>
    </div>
    <div
      class="modal fade"
      id="addressModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="addressModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!isUpdate">Novo Endereço (digite o CEP primeiro para preencher)</h5>
            <h5 class="modal-title" v-else>Atualizar Endereço</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="name" class="form-label">Nome do Familiar</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="address.name"
                />
              </div>
              <div class="col-md-2">
                <label for="name" class="form-label">Contato 1</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="20"
                  v-model="address.contact1"
                />
              </div>
              <div class="col-md-2">
                <label for="name" class="form-label">Observação Contato 1</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="20"
                  v-model="address.obsContact1"
                />
              </div>
              <div class="col-md-2">
                <label for="name" class="form-label">Contato 2</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="20"
                  v-model="address.contact2"
                />
              </div>
              <div class="col-md-2">
                <label for="name" class="form-label">Observação Contato 2</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="20"
                  v-model="address.obsContact2"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-2">
                <label for="zip" class="form-label">CEP</label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  v-mask="'#####-###'"
                  v-model="address.zip"
                  @change="getZipCode"
                />
              </div>
              <div class="col-md-5">
                <label for="street" class="form-label">Endereço</label>
                <input
                  type="text"
                  class="form-control"
                  id="street"
                  v-model="address.street"                  
                />
              </div>
              <div class="col-md-1">
                <label for="number" class="form-label">Número</label>
                <input
                  type="text"
                  class="form-control"
                  id="number"
                  v-model="address.number"
                />
              </div>
              <div class="col-md-4">
                <label for="district" class="form-label">Bairro</label>
                <input
                  type="text"
                  class="form-control"
                  id="district"
                  v-model="address.district"
                />
              </div>
              
              
              
            </div>
            <div class="row mt-2">
              <div class="col-md-2">
                <label for="city" class="form-label">Cidade</label>
                <!-- <select class="form-select" v-model="address.city">
                  <option
                    v-for="(city, id) in cities"
                    :key="id"
                    :value="city.id"
                  >
                    {{ city.attributes.Name }}
                  </option>
                </select> -->
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  v-model="address.city"
                />
              </div>
              <div class="col-md-1">
                <label for="state" class="form-label">Estado</label>
                <!-- <select class="form-select" v-model="address.state" @change="getCitiesByState">
                  <option
                    v-for="(state, id) in states"
                    :key="id"
                    :value="state.id"
                  >
                    {{ state.attributes.Abbreviation }}
                  </option>
                </select> -->
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  v-model="address.state"
                />
              </div>
              <div class="col-md-2">
                <label for="complement" class="form-label">Complemento</label>
                <input
                  type="text"
                  class="form-control"
                  id="complement"
                  v-model="address.complement"
                />
              </div>
              <div class="col-md-3">
                <label for="country" class="form-label">País</label>
                <select class="form-select" v-model="address.country">
                  <option
                    v-for="(country, id) in countries"
                    :key="id"
                    :value="country.id"
                  >
                    {{ country.attributes.Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn button"
              data-bs-dismiss="modal"
              @click="addAddress"
              v-if="!isUpdate"
            >
              Adicionar
            </button>
            <button
              type="button"
              class="btn button"
              data-bs-dismiss="modal"
              @click="updateAddress(currentAddress)"
              v-else
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Address from "@/services/address";
import City from "@/services/city";
import State from "@/services/state";
import Country from "@/services/country";
import Zip from "@/services/zipcode";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import AddressValidation from "@/validation/Address";

export default {
  name: "Address",
  props: ["trigger"],
  data() {
    return {
      currentAddress: null,
      isUpdate: false,
      addresses: [],
      cities: [],
      states: [],
      countries: [],
      address: {
        id: null,
        name: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        zip: null,
        city: null,
        state: null,
        country: null,
        contact1: null,
        obsContact1: null,
        contact2: null,
        obsContact2: null
      },
    };
  },
  watch: {
    trigger: function() {
        this.saveAddress();
    }
  },
  methods: {
    validate() {
      if (!CommomValidation.isFieldsAreNull(this.address)) {
        const errors = AddressValidation.validate(this.address);

        return errors;
      }
    },
    saveAddress() {
      this.addresses.forEach(address => {
        Address.saveOrUpdate(address)
          .then((response) => {
            address.id = response.data.data.id;
          })
          .catch((error) => {
            if (!error.response)
              this.$toast.error("Erro ao tentar se conectar com o servidor.");
            else
              console.log(error);
          });
      });
    },
    addAddress() {
      const errors = this.validate()

      if (errors.length == 0) {
        this.addresses.push(JSON.parse(JSON.stringify(this.address)));
        //console.log(this.address);
        this.clear();
      } else {
        errors.forEach((error) => {
          this.$toast.error(error);
        });
      }
    },
    updateAddress(id) {
      const errors = this.validate()

      if (errors.length == 0)
        this.addresses[id] = JSON.parse(JSON.stringify(this.address));
      else
        errors.forEach((error) => {
          this.$toast.error(error);
        });

      this.clear();
      this.isUpdate = false;
    },
    getCitiesByState(event) {
      // if (event.target.value.length == 9)
      City.getCitiesByState(event.target.value)
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getZipCode(event) {
      if (event.target.value.length == 9)
      {
        Zip.getZipCodeAddress(event.target.value)
          .then((response) => {
            this.address.street = response.data.logradouro;
            this.address.city = response.data.localidade;
            this.address.district = response.data.bairro;
            this.address.state = response.data.uf;

            // City.getCityByName(this.address.city)
            //   .then((response) => {
            //     console.log(response.data);
            //   })
            //   .catch((error) => {
            //     if (!error.response)
            //       console.log("Erro ao tentar se encontrar a cidade.");
            //   });
            
            //console.log(this.cities);
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se comunicar com API viacep.");
          });
      }
    },
    edit(id) {
      if (this.addresses[id].id != undefined)
        this.address.id = this.addresses[id].id;

      this.address.name = this.addresses[id].name;
      this.address.street = this.addresses[id].street;
      this.address.number = this.addresses[id].number;
      this.address.complement = this.addresses[id].complement;
      this.address.district = this.addresses[id].district;
      this.address.zip = this.addresses[id].zip;
      this.address.city = this.addresses[id].city;
      this.address.state = this.addresses[id].state;
      this.address.country = this.addresses[id].country;
      this.address.contact1 = this.addresses[id].contact1;
      this.address.obsContact1 = this.addresses[id].obsContact1;
      this.address.contact2 = this.addresses[id].contact2;
      this.address.obsContact2 = this.addresses[id].obsContact2;

      this.isUpdate = true;
      this.currentAddress = id;
    },
    remove(id) {
      if (this.addresses[id].id) {
        Address.remove(this.addresses[id].id)
          .then(() => {
            this.addresses.splice(id, 1);
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
      } else {
        this.addresses.splice(id, 1);
      }
    },
    clear() {
      for (let [item] of Object.entries(this.address)) {
        this.address[item] = null;
      }

      this.isUpdate = false;
    },
    getStates() {
      State.all()
        .then((response) => {
          if (response.data.data.length > 0) this.states = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getCountries() {
      Country.all()
        .then((response) => {
          if (response.data.data)
            this.countries = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getAddress() {
      if (sessionStorage.getItem("updateProfileId"))
        Address.findByProfileId(sessionStorage.getItem("updateProfileId"))
          .then((response) => {
            if (response.data.data) {
              response.data.data.forEach(address => {
                this.address.id = address.id;
                this.address.name = address.attributes.Name;
                this.address.street = address.attributes.Street;
                this.address.number = address.attributes.Number;
                this.address.complement = address.attributes.Complement;
                this.address.district = address.attributes.District;
                this.address.zip = address.attributes.Zip;
                this.address.city = address.attributes.City;
                this.address.state = address.attributes.State;
                this.address.country = address.attributes.country.data?.id;
                this.address.contact1 = address.attributes.Contact1;
                this.address.obsContact1 = address.attributes.ObsContact1;
                this.address.contact2 = address.attributes.Contact2;
                this.address.obsContact2 = address.attributes.ObsContact2;
                
                this.addresses.push(JSON.parse(JSON.stringify(this.address)));
              });
            }
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com a API Address: ", error);
          });
    },
  },
  mounted() {
    this.getAddress();
    this.getStates();
    this.getCountries();
  },
};
</script>

<style scoped>
label {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

label {
  color: #828282;
}

span {
  color: red;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #f8af2f;
}

.table {
  width: 90%;
}

.td {
  font-size: 16px;
  line-height: 18px;
  color: #193250;
}

a {
  text-decoration: none;
}

tr:hover {
  border: 1px solid #033F6D;
}
</style>
