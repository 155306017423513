export default {
    validate: (academic) => {
        let errors = [];

        if (academic.title && (academic.title.length > 20))
            errors.push("Título deve ter no máximo 20 caracteres");
        
        if (academic.level && (academic.level.length > 20))
            errors.push("Nível deve ter no máximo 20 caracteres");
        
        if (academic.institution && (academic.institution.length > 50))
            errors.push("INstituição deve ter no máximo 50 caracteres");

        return errors;
    }
}