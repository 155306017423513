import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    all: () => {
        return HTTP.get("addresses", {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    findByProfileId: (profile) => {
        return HTTP.get(`addresses/${profile}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: (id) => {
        return HTTP.delete(`addresses/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (address) => {
        const data = {
            Name: address.name,
            Street: address.street,
            Number: address.number,
            Complement: address.complement,
            District: address.district,
            ZipCode: address.zip,
            Contact1: address.contact1,
            ObsContact1: address.obsContact1,
            Contact2: address.contact2,
            ObsContact2: address.obsContact2,
            profile: sessionStorage.getItem("updateProfileId"),
            City: address.city,
            State: address.state,
            country: address.country
        }

        if (!address.id)
            return HTTP.post("addresses", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        else {
            return HTTP.put(`addresses/${address.id}`, { data }, {
                    headers: {
                        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                    }
                });
        }
    }
}