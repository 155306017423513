export default {
    validate: (address) => {
        let errors = [];
        
        if (address.zip.length < 9)
            errors.push("CEP Inválido");

        if (address.name && (address.name.length > 50 || address.name.length < 2))
            errors.push("Nome deve ter entre 2 e 50 caracteres");
        
        if (address.street && (address.street.length > 50 || address.street.length < 3))
            errors.push("Endereço deve ter entre 3 e 50 caracteres");
        
        if (address.number && (address.number.length > 10))
            errors.push("Número deve ter no máximo 10 caracteres");
        
        if (address.complement && (address.complement.length > 10))
            errors.push("Complemento deve ter no máximo 10 caracteres");
        
        if (address.district && (address.district.length > 50 || address.district.length < 3))
            errors.push("Bairro deve ter entre 3 e 50 caracteres");

        return errors;
    }
}