import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findByProfileId: (profileId) => {
        return HTTP.get(`formation-academics/byprofile/${profileId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: (id) => {
        return HTTP.delete(`formation-academics/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (academic) => {
        const data = {
            Title: academic.title,
            Level: academic.level,
            Institution: academic.institution,
            Conclusion: academic.conclusion,
            city: academic.city,
            state: academic.state,
            profile: sessionStorage.getItem("updateProfileId"),
        }

        if (!academic.id)
            return HTTP.post("formation-academics", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        
        return HTTP.put(`formation-academics/${academic.id}`, { data }, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });   
    },
}