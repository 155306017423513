<template>
  <div>
    <h1 class="mt-5">Formação Acadêmica</h1>
    <table class="table table-bordered mt-3">
      <thead class="thead-orange">
        <tr>
          <th>TÍTULO</th>
          <th>NÍVEL</th>
          <th>INSTITUIÇÃO</th>
          <th>CIDADE</th>
          <th>ESTADO</th>
          <th>CONCLUSÃO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(academic, id) in academics" :key="id">
          <td class="td">{{ academic.title ?? "" }}</td>
          <td class="td">{{ academic.level ?? "" }}</td>
          <td class="td">{{ academic.institution ?? "" }}</td>
          <td class="td">{{ toCityName(academic.city) ?? "" }}</td>
          <td class="td">{{ toStateAbbreviation(academic.state) ?? "" }}</td>
          <td class="td">{{ toDateFormatBR(academic.conclusion) ?? "" }}</td>
          <td class="td d-flex justify-content-evenly">
            <span class="material-icons">
              <a
                href=""
                style="color: #00c0c9"
                @click="edit(id)"
                data-bs-toggle="modal"
                data-bs-target="#academicModal">
                edit
              </a>
            </span>
            <span class="material-icons">
              <a href="" style="color: #cf3900" @click.prevent="remove(id)">
                delete
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-2">
      <button
        type="button"
        class="btn button"
        data-bs-toggle="modal"
        data-bs-target="#academicModal"
        @click="clear"
      >
        Adicionar Formação
      </button>
    </div>
    <div
      class="modal fade"
      id="academicModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="academicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!isUpdate">Nova Formação</h5>
            <h5 class="modal-title" v-else>Atualizar Formação</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col">
                <label for="title" class="form-label">Título</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  maxlength="50"
                  v-model="academic.title"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <label for="level" class="form-label">Nível</label>
                <input
                  type="text"
                  class="form-control"
                  id="level"
                  placeholder="Ex: Superior"
                  v-model="academic.level"
                />
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="institution" class="form-label"
                    >Instituição</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="institution"
                    placeholder="Ex: Universidade de São Paulo"
                    v-model="academic.institution"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="state" class="form-label">Estado</label>
                  <select class="form-select" v-model="academic.state" @change="getCitiesByState">
                    <option
                      v-for="(state, id) in states"
                      :key="id"
                      :value="state.id"
                    >
                      {{ state.attributes.Abbreviation }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="city" class="form-label">Cidade</label>
                  <select class="form-select" v-model="academic.city">
                    <option
                      v-for="(city, id) in cities"
                      :key="id"
                      :value="city.id"
                    >
                      {{ city.attributes.Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="conclusion" class="form-label">Conclusão</label>
                  <input
                    type="date"
                    class="form-control"
                    id="conclusion"
                    v-model="academic.conclusion"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn button"
                @click="addAcademic"
                v-if="!isUpdate"
                data-bs-dismiss="modal"
              >
                Adicionar
              </button>
              <button
                type="button"
                class="btn button"
                data-bs-dismiss="modal"
                @click="updateAcademic(currentAcademic)"
                v-else
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Academic from "@/services/academic";
import City from "@/services/city";
import State from "@/services/state";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import AcademicValidation from "@/validation/Academic";

export default {
  name: "Academic",
  props: ["trigger"],
  data() {
    return {
      academics: [],
      cities: [],
      states: [],
      isUpdate: false,
      currentAcademic: null,
      academic: {
        id: null,
        title: null,
        level: null,
        institution: null,
        city: null,
        state: null,
        conclusion: null,
        profileId: null
      },
    };
  },
  watch: {
    trigger: function() {
        this.saveAcademic();
    },
  },
  methods: {
    saveAcademic() {
      this.academics.forEach(academic => {
        Academic.saveOrUpdate(academic)
          .then((response) => {
            academic.id = response.data.data.id;
          })
          .catch((error) => {
            if (!error.response)
              this.$toast.error("Erro ao tentar se conectar com o servidor.");
            else
              console.log(error);
          });
      });
    },
    addAcademic() {
      if (!CommomValidation.isFieldsAreNull(this.academic)) {
        const errors = AcademicValidation.validate(this.academic);

        if (errors.length == 0) {
          this.academics.push(JSON.parse(JSON.stringify(this.academic)));
          this.clear();
        } else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }
    },
    updateAcademic(id) {
      if (!CommomValidation.isFieldsAreNull(this.academic)) {
        const errors = AcademicValidation.validate(this.academic);

        if (errors.length == 0) {
          this.academics[id] = JSON.parse(JSON.stringify(this.academic));
        }
        else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }

      this.clear();
      this.isUpdate = false;
    },
    edit(id) {
      if (this.academics[id].id != undefined)
        this.academic.id = this.academics[id].id;

      this.academic.title = this.academics[id].title;
      this.academic.level = this.academics[id].level;
      this.academic.institution = this.academics[id].institution;
      this.academic.city = this.academics[id].city;
      this.academic.state = this.academics[id].state;
      this.academic.conclusion = this.academics[id].conclusion;
      this.academic.profileId = this.academics[id].profileId;

      this.isUpdate = true;
      this.currentAcademic = id;
    },
    remove(id) {
      if (this.academics[id].id) {
        Academic.remove(this.academics[id].id)
          .then(() => {
            this.academics.splice(id, 1);
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
      } else {
        this.academics.splice(id, 1);
      }
    },
    clear() {
      for (let [item] of Object.entries(this.academic)) {
        this.academic[item] = null;
      }

      this.isUpdate = false;
    },
    getAcademic() {
      if (sessionStorage.getItem("updateProfileId"))
        Academic.findByProfileId(sessionStorage.getItem("updateProfileId"))
          .then((response) => {
            if (response.data.data)
              response.data.data.forEach(academic => {
                this.academic.id = academic.id;
                this.academic.title = academic.attributes.Title;
                this.academic.level = academic.attributes.Level;
                this.academic.institution = academic.attributes.Institution;
                this.academic.city = academic.attributes.city.data?.id;
                this.academic.state = academic.attributes.state.data?.id;
                this.academic.conclusion = academic.attributes.Conclusion;
                this.academics.push(JSON.parse(JSON.stringify(this.academic)));
              });
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
    },
    getStates() {
      State.all()
        .then((response) => {
          if (response.data.data) this.states = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getCitiesByState(event) {
      City.getCitiesByState(event.target.value)
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    toCityName(cityId) {
      return this.cities.find((city) => city.id == cityId)?.attributes.Name;
    },
    toStateAbbreviation(stateId) {
      return this.states.find((state) => state.id == stateId)?.attributes.Abbreviation;
    },
    toDateFormatBR(date) {
      if (date) {
        var data = new Date(date);
        return data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
      }
    },
  },
  mounted() {
    this.getStates();
    this.getAcademic();
  },
};
</script>

<style scoped>
h1,
label,
.td {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 18px;
  line-height: 21px;
  color: #193250;
}

label {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.table {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.thead-orange {
  background: #033F6D;
  border-radius: 6px 6px 0px 0px;
}

th {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.td {
  font-size: 16px;
  line-height: 18px;
  color: #193250;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #f8af2f;
}

a {
  text-decoration: none;
}
</style>
