import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findByProfileId: (profileId) => {
        return HTTP.get(`religious-historics/byprofile/${profileId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: (id) => {
        return HTTP.delete(`religious-historics/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (historic) => {
        const data = {
            comunity: historic.comunity,
            Begin: historic.begin,
            End: historic.end,
            Occupation: historic.occupation,
            Observation: historic.observation,
            city: historic.city,
            profile: sessionStorage.getItem("updateProfileId"),
        }
        if (!historic.id)
            return HTTP.post("religious-historics", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        
        return HTTP.put(`religious-historics/${historic.id}`, { data }, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        }); 
    },
}