export default {
    validate: (document) => {
        const errors = [];

        if (document.rg && document.rg.length < 8)
            errors.push("RG inválido");
        
        if (document.cpf && document.cpf.length < 14)
            errors.push("CPF inválido");
        
        if (document.voteCard && document.voteCard.length > 20)
            errors.push("Título de Eleitor deve ter no máximo 20 caracteres");

        if (document.zone && document.zone.length > 10)
            errors.push("Zona deve ter no máximo 10 caracteres");

        if (document.section && document.section.length > 10)
            errors.push("Seção deve ter no máximo 10 caracteres");

        if (document.SocialSecurity && document.rg.SocialSecurity > 20)
            errors.push("Previdência Social deve ter no máximo 20 caracteres");

        if (document.pis && document.rg.length > 20)
            errors.push("PIS deve ter no máximo 20 caracteres");

        if (document.agencyCode && document.agencyCode.length > 10)
            errors.push("Código Agência deve ter no máximo 10 caracteres");

        if (document.agencyStreet && (document.agencyStreet.length > 50 || document.agencyStreet.length < 3))
            errors.push("Endereço Agência deve ter entre 3 e 50 caracteres");

        if (document.number && document.number.length > 10)
            errors.push("Número deve ter no máximo 10 caracteres");

        if (document.district && (document.district.length > 50 || document.district.length < 3))
            errors.push("Bairro deve ter entre 3 e 50 caracteres");

        if (document.passport && document.passport.length > 20)
            errors.push("Passaporte deve ter no máximo 20 caracteres");

        return errors;
    }
}