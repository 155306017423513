<template>
    <Header />
    <div class="container">
    <div class="row">
      <Sidebar
        class="col-md-3 col-lg-2"
        :items="['Início', 'Cadastros', '- Usuário', '- Comunidade']"
        :routes="['/admin', '/admin/registers', '/admin/new', '/admin/comunity']"
      />
    <div class="col-md-9 col-lg-10 px-md-4">
        <div class="row">
          <div class="col">
            <h4 style="margin-top: 20px; color: #033F6D;">NOVO CADASTRO</h4>
          </div>
          <div class="col text-end">
            <h4 class="text2" style="margin-top: 20px;">
              Inicial / <span style="color: #033F6D;">Novo cadastro</span>
            </h4>
          </div>
        </div>
      <div class="main">
        <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="personal-tab" data-bs-toggle="tab" data-bs-target="#personal" type="button" role="tab" aria-controls="personal" aria-selected="true">Dados Pessoais</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="false">Documentos</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address" type="button" role="tab" aria-controls="address" aria-selected="false">Endereços</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="formation-tab" data-bs-toggle="tab" data-bs-target="#formation" type="button" role="tab" aria-controls="formation" aria-selected="false">Formação</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo" type="button" role="tab" aria-controls="photo" aria-selected="false">Fotos</button>
          </li>
          <li class="nav-item justify-content-center d-flex" id="active">
            <label class="form-check-label me-2" for="active">Ativo</label>
            <input class="form-check-input" type="checkbox" id="active" v-model="active">
          </li>
          <li class="nav-item">
            <button class="btn btn-success" id="button-save" @click="validate" onclick="setFormSubmitting()">Salvar</button>
          </li>
        </ul>

          <div class="tab-content ms-3 me-3" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="personal"
              role="tabpanel"
              aria-labelledby="personal-tab"
            >
              <Profile
                :validateTrigger="profileValidateTrigger"
                :saveTrigger="profileSaveTrigger"
                :active="active"
                @activeInput="activeProfile"
                @errors="concatErrors"
              />
            </div>
            <div
              class="tab-pane fade"
              id="documents"
              role="tabpanel"
              aria-labelledby="documents-tab"
            >
              <Documents
                :validateTrigger="documentValidateTrigger"
                :saveTrigger="documentSaveTrigger"
                @errors="concatErrors"
              />
            </div>
            <div
              class="tab-pane fade"
              id="address"
              role="tabpanel"
              aria-labelledby="address-tab"
            >
              <Address :trigger="addressSaveTrigger" />
            </div>
            <div
              class="tab-pane fade"
              id="formation"
              role="tabpanel"
              aria-labelledby="formation-tab"
            >
              <Formation
                :validateTrigger="formationValidateTrigger"
                :saveTrigger="formationSaveTrigger"
                @errors="concatErrors"
              />
            </div>
            <div
              class="tab-pane fade"
              id="photo"
              role="tabpanel"
              aria-labelledby="photo-tab"
            >
              <Gallery :saveTrigger="gallerySaveTrigger" />
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
</template>

<script>
  import Header from "@/components/Header.vue";
  import Sidebar from "@/components/Sidebar.vue";
  import Profile from "@/components/Profile.vue";
  import Documents from "@/components/Documents.vue";
  import Address from "@/components/Address.vue";
  import Formation from "@/components/Formation.vue";
  import Gallery from "@/components/Gallery.vue";

  import ProfileService from "@/services/profile";

  export default {
    name: "Forms",
    components: {
      Sidebar,
      Header,
      Profile,
      Documents,
      Address,
      Formation,
      Gallery,
    },
    data() {
      return {
        errors: [],
        profileValidateTrigger: false,
        documentValidateTrigger: false,
        formationValidateTrigger: false,
        profileSaveTrigger: false,
        documentSaveTrigger: false,
        addressSaveTrigger: false,
        formationSaveTrigger: false,
        gallerySaveTrigger: false,
        active: false,
        name: "Novo Cadastro",
        formSubmitting: false
      }
    },
    created() {
      if (sessionStorage.getItem("updateProfileId")) {
        ProfileService.findOne(sessionStorage.getItem("updateProfileId"))
        .then((response) => {
          if (response.data.data) {
            this.name = response.data.data.attributes.FirstName + " " + response.data.data.attributes.LastName;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    methods: {
      activeProfile(input) {
        this.active = input;
      },
      concatErrors(errors) {
        if (errors.length > 0) {
          errors.forEach(error => {
            this.errors.push(error);
          });
        }
      },
      validate() {
        this.profileValidateTrigger = !this.profileValidateTrigger;
        this.documentValidateTrigger = !this.documentValidateTrigger;
        this.formationValidateTrigger = !this.formationValidateTrigger;
        this.formSubmitting = true;

        setTimeout(() => {
          this.saveOrUpdate();
        }, 500);
      },
      saveOrUpdate() {
        if (this.errors.length == 0) {
          this.saveProfile();

          setTimeout(() => {
            this.saveDocument();
            this.saveAddress();
            this.saveFormation();
            this.saveGallery();
            this.$toast.success("Dados salvos com sucesso");
            this.$router.push("/admin");
          }, 500);
        } else {
          this.errors.forEach((error) => {
            this.$toast.info(error);
          });
        }

        this.errors = [];
      },
      saveProfile() {
        this.profileSaveTrigger = !this.profileSaveTrigger;
      },
      saveDocument() {
        this.documentSaveTrigger = !this.documentSaveTrigger;
      },
      saveAddress() {
        this.addressSaveTrigger = !this.addressSaveTrigger;
      },
      saveFormation() {
        this.formationSaveTrigger = !this.formationSaveTrigger;
      },
      saveGallery() {
        this.gallerySaveTrigger = !this.gallerySaveTrigger;
      },
    },
    beforeRouteLeave (to, from , next) {
      if (this.formSubmitting)
        next()
      else {
        const answer = window.confirm('Ao sair dessa página sem salvar você pode perder os dados. Deseja realmente sair?')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    }
  }
    
  
</script>

<style scoped>

.container {
  min-width: 100%;
  width: 100vw;
}

.main {
  padding: 20px 0px 0 20px;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: rgb(223, 232, 240);
  min-height: 80vh;
}

.title {
  color: #033F6D;
}

.main-content {
  background-color: rgba(244, 244, 244, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-link {
  font-family: Ubuntu;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #193250;
  border-bottom: 3px solid #033F6D;
}

.nav-link.active {
  border-color: transparent #ffffff;
  font-weight: bold;
}
.nav-link:hover {
  border-bottom: 3px solid #ffffff;
}

#button-save {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

#active {
  align-items: center;
}

#button-save:hover {
  background: #f8af2f;
}

/* checkbox slider */
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 29px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(42px);
  -ms-transform: translateX(42px);
  transform: translateX(42px);
}

.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 12px;
  font-family: Verdana, sans-serif;
}

.on {
  left: 40%;
}

.off {
  left: 60%;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>