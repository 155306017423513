import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    all: () => {
        return HTTP.get("cities", {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    getCitiesByState: (stateId) => {
        return HTTP.get(`cities/bystate/${stateId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    getCityByName: (cityName) => {
        return HTTP.get(`cities/byname/${cityName}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    }
}