import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findOne: (documentId) => {
        return HTTP.get(`documents/${documentId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: (id) => {
        return HTTP.delete(`documents/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (document) => {
        const data = {
            Rg: document.rg,
            Issue: document.issue,
            Cpf: document.cpf,
            VoteCard: document.voteCard,
            Zone: document.zone,
            Section: document.setion,
            SocialSecurity: document.socialSecurity,
            Pis: document.pis,
            PisDate: document.pisDate,
            AgencyCode: document.agencyCode,
            AgencyStreet: document.agencyStreet,
            Number: document.number,
            District: document.district,
            Passport: document.passport,
            city: document.city,
            state: document.state,
            issuer: document.issuer,
            profile: sessionStorage.getItem("updateProfileId")
        }
        console.log('SAVEE')
        console.log(data)

        if (!document.id)
            return HTTP.post("documents", { data }, {
                    headers: {
                        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                    }
                });
        else {
            return HTTP.put(`documents/${document.id}`, { data }, {
                    headers: {
                        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                    }
                });
        }
    }
}