export default {
    validate: (formation) => {
        let errors = [];

        if (!formation.baptismDate)
            errors.push("Data Batismo é obrigatório");

        if (!formation.chrismDate)
            errors.push("Data Crisma é obrigatório");

        return errors;
    }
}