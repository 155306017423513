import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    all: () => {
        return HTTP.get("states", {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
}