<template>
  <div>
    <h1 class="mt-4">Formação Religiosa</h1>
    <div class="row mt-2">
      <div class="col-md-3">
        <label for="baptismdate" class="form-label"
          >Data Batismo
          <span>(Obrigatório)</span>
        </label>
        <input
          type="date"
          class="form-control"
          id="baptismdate"
          v-model="formation.baptismDate"
        />
      </div>
      <div class="col-md-3">
        <label for="chrismdate" class="form-label"
          >Data Crisma
          <span>(Obrigatório)</span>
        </label>
        <input
          type="date"
          class="form-control"
          id="chrismdate"
          v-model="formation.chrismDate"
        />
      </div>
    </div>
    <Period :trigger="periodTrigger" />
    <Academic :trigger="academicTrigger" />
    <Historic :trigger="historicTrigger" />
  </div>
</template>

<script>
import Formation from "@/services/formation";

import Period from "@/components/Period";
import Academic from "@/components/Academic";
import Historic from "@/components/Historic";

/** Validations */
import FormationValidation from "@/validation/Formation";

export default {
  name: "Formation",
  components: {
    Period,
    Academic,
    Historic
  },
  props: ["validateTrigger", "saveTrigger"],
  data() {
    return {
      academicTrigger: false,
      historicTrigger: false,
      periodTrigger: false,
      formation: {
        id: null,
        baptismDate: null,
        chrismDate: null,
        profile: null
      },
    };
  },
  watch: {
    validateTrigger: function() {
      this.validate();
    },
    saveTrigger: function() {
      this.saveFormation();
      this.periodTrigger = !this.periodTrigger;
      this.historicTrigger = !this.historicTrigger;
      this.academicTrigger = !this.academicTrigger;
    }
  },
  methods: {
    validate() {
      const errors = FormationValidation.validate(this.formation);
      this.$emit("errors", errors);
    },
    saveFormation() {
      Formation.saveOrUpdate(this.formation)
        .then((response) => {
          this.formation.id = response.data.data.id;
        })
        .catch((error) => {
          if (!error.response)
            this.$toast.error("Erro ao tentar se conectar com o servidor.");
          else
            console.log(error);
        });
    },
    clear() {
      for (let [item] of Object.entries(this.formation)) {
        this.formation[item] = null;
      }
    },
    getFormation() {
      if (sessionStorage.getItem("updateProfileId"))
        Formation.findOne(sessionStorage.getItem("updateProfileId"))
          .then((response) => {
            if (response.data.data) {
                this.formation.id = response.data.data.id;
                this.formation.chrismDate = response.data.data.attributes.ChrismDate;
                this.formation.baptismDate = response.data.data.attributes.BaptismDate;
            }
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
    }
  },
  mounted() {
    this.getFormation();
  }
};
</script>

<style scoped>
label,
span,
h1 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

h1 {
  font-size: 18px;
  line-height: 21px;
  color: #193250;
}

label {
  color: #828282;
}

span {
  color: red;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}
</style>
