import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findByProfileId: (profileId) => {
        return HTTP.get(`formation-times/byprofile/${profileId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: (id) => {
        return HTTP.delete(`formation-times/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (period) => {
        const data = {
            Type: period.type,
            Location: period.location,
            Year: period.year,
            comunity: period.comunity,
            profile: sessionStorage.getItem("updateProfileId"),
            Observation: period.observation
        }

        if (!period.id)
            return HTTP.post("formation-times", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        else {
            return HTTP.put(`formation-times/${period.id}`, { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        }
    }
}