import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findOne: (profileId) => {
        return HTTP.get(`formations/${profileId}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: (formation) => {
        const data = {
            BaptismDate: formation.baptismDate,
            ChrismDate: formation.chrismDate,
            profile: sessionStorage.getItem("updateProfileId")
        }

        if (!formation.id)
            return HTTP.post("formations", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
        else
            return HTTP.put(`formations/${formation.id}`, { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
    },
}