<template>
  <form class="g-3" v-on:submit.prevent>
    <div class="row mt-3">
      <div class="col-md-9">
        <div class="row mt-2">
          <div class="col-md-5">
            <label for="firstname" class="form-label">
              Nome
              <span>(Obrigatório)</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              v-model="profile.firstName"
            />
          </div>
          <div class="col-md-7">
            <label for="lastname" class="form-label">
              Sobrenome
              <span>(Obrigatório)</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="profile.lastName"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-5">
            <label for="nickname" class="form-label">Nome Religioso</label>
            <input
              type="text"
              class="form-control"
              id="nickname"
              v-model="profile.nickName"
            />
          </div>
          <div class="col-md-7">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="profile.email"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-5">
            <label for="birthdate" class="form-label">
              Data de Nascimento
              <span>(Obrigatório)</span>
            </label>
            <input
              type="date"
              class="form-control"
              id="birthdate"
              v-model="profile.birthdate"
            />
          </div>
          <div class="col-md-5">
            <label for="decease" class="form-label">Data de Falecimento</label>
            <input
              type="date"
              class="form-control"
              id="decease"
              v-model="profile.decease"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label for="photo" class="form-label">
          Foto
        </label>
        <div class="card">
          <img v-if="tempUrl" :src="tempUrl" class="card-img-top">
          <input
            style="display: none"
            type="file"
            class="mb-3"
            @change="onFileSelected"
            ref="fileInput"
          />
          <button
            @click="$refs.fileInput.click()"
            class="btn picture"
          >
            Escolher foto
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <label for="state" class="form-label">Estado</label>
        <select class="form-select" v-model="profile.state" @change="getCitiesByState">
          <option v-for="(state, id) in states" :key="id" :value="state.id">
            {{ state.attributes.Name }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="city" class="form-label">Naturalidade</label>
        <select class="form-select" v-model="profile.city">
          <option v-for="(city, id) in cities" :key="id" :value="city.id">
            {{ city.attributes.Name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="country" class="form-label"
          >Nacionalidade
          <span>(Obrigatório)</span>
        </label>
        <select class="form-select" v-model="profile.country">
          <option
            v-for="(country, id) in countries"
            :key="id"
            :value="country.id"
          >
            {{ country.attributes.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label for="fathername" class="form-label">Nome do Pai</label>
        <input
          type="text"
          class="form-control"
          id="fathername"
          v-model="profile.fatherName"
        />
      </div>
      <div class="col-md-4">
        <label for="fatherProfession" class="form-label">Profissão do Pai</label>
        <input
          type="text"
          class="form-control"
          id="fatherProfession"
          v-model="profile.fatherProfession"
        />
      </div>
      <div class="col-md-4">
        <label for="fatherCountry" class="form-label">Nacionalidade do Pai</label>
        <select class="form-select" v-model="profile.fatherCountry">
          <option
            v-for="(country, id) in countries"
            :key="id"
            :value="country.id"
          >
            {{ country.attributes.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label for="mothername" class="form-label">Nome da Mãe</label>
        <input
          type="text"
          class="form-control"
          id="mothername"
          v-model="profile.motherName"
        />
      </div>
      <div class="col-md-4">
        <label for="motherProfession" class="form-label">Profissão da Mãe</label>
        <input
          type="text"
          class="form-control"
          id="motherProfession"
          v-model="profile.motherProfession"
        />
      </div>
      <div class="col-md-4">
        <label for="motherCountry" class="form-label">Nacionalidade da Mãe</label>
        <select class="form-select" v-model="profile.motherCountry">
          <option
            v-for="(country, id) in countries"
            :key="id"
            :value="country.id"
          >
            {{ country.attributes.Name }}
          </option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>
import City from "@/services/city";
import State from "@/services/state";
import Country from "@/services/country";
import Profile from "@/services/profile";
import Image from "@/services/upload";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import ProfileValidation from "@/validation/Profile";

//var isDirty = function() { return false; }

export default {
  name: "Profile",
  props: ["validateTrigger", "saveTrigger", "active"],
  data() {
    return {
      cities: [],
      states: [],
      countries: [],
      selectedFile: null,
      tempUrl: null,
      profile: {
        id: null,
        firstName: null,
        lastName: null,
        nickName: null,
        birthdate: null,
        decease: null,
        email: null,
        active: false,
        photo: null,
        city: null,
        country: null,
        comunity: null,
        gallery: null,
        state: null,
        fatherName: null,
        fatherProfession: null,
        fatherCountry: null,
        motherName: null,
        motherProfession: null,
        motherCountry: null
      },
    };
  },
  watch: {
    validateTrigger: function() {
      this.validate();
    },
    saveTrigger: function() {
        this.saveProfile();
    }
  },
  methods: {
    validate() {
      if (!CommomValidation.isFieldsAreNull(this.profile)) {
        const errors = ProfileValidation.validate(this.profile);
        this.$emit("errors", errors);
      }
    },
    saveProfile() {
      this.profile.active = this.active;

      Profile.saveOrUpdate(this.profile)
        .then((response) => {
          this.profile.id = response.data.data.id;
          sessionStorage.setItem("updateProfileId", this.profile.id);
          this.uploadFile();
        })
        .catch((error) => {
            console.log(error);
        });
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.tempUrl = URL.createObjectURL(this.selectedFile);
    },
    getCitiesByState(event) {
      City.getCitiesByState(event.target.value)
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    uploadFile() {
      if (this.selectedFile) {
        const form = new FormData();
        form.append("files", this.selectedFile);
        form.append("ref", "api::profile.profile");
        form.append("refId", this.profile.id);
        form.append("field", "Photo");

        if (this.profile.photo)
          Image.remove(this.profile.photo)
            .catch((error) => {
                console.log(error);
            });

        Image.upload(form)
          .catch((error) => {
              console.log(error);
          });
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("updateProfileId"))
      Profile.findOne(sessionStorage.getItem("updateProfileId"))
        .then((response) => {
          if (response.data.data) {
            //console.log(response.data.data);
            this.profile.id = response.data.data.id;
            this.profile.firstName = response.data.data.attributes.FirstName;
            this.profile.lastName = response.data.data.attributes.LastName;
            this.profile.nickName = response.data.data.attributes.Nickname;
            this.profile.birthdate = response.data.data.attributes.Birthdate;
            this.profile.decease = response.data.data.attributes.Decease;
            this.profile.email = response.data.data.attributes.Email;
            this.profile.active = response.data.data.attributes.Active;
            this.profile.city = response.data.data.attributes.city.data?.id;
            this.profile.country = response.data.data.attributes.country.data.id;
            this.profile.gallery = response.data.data.attributes.gallery.data?.id;
            this.profile.state = response.data.data.attributes.state.data?.id;
            this.profile.fatherName = response.data.data.attributes.FatherName;
            this.profile.fatherProfession = response.data.data.attributes.FatherProfession;
            this.profile.fatherCountry = response.data.data.attributes.FatherCountry.data?.id;
            this.profile.motherName = response.data.data.attributes.MotherName;
            this.profile.motherProfession = response.data.data.attributes.MotherProfession;
            this.profile.motherCountry = response.data.data.attributes.MotherCountry.data?.id;
            
            if (response.data.data.attributes.Photo.data) {
              this.profile.photo = response.data.data.attributes.Photo.data.id;
              this.tempUrl = "https://api.associadas.dominicanas.org.br" + response.data.data.attributes.Photo.data.attributes.formats.thumbnail.url;
            }
            
            this.$emit("activeInput", this.profile.active);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    State.all()
      .then((response) => {
        if (response.data.data) this.states = response.data.data;
      })
      .catch((error) => {
        if (!error.response)
          console.log("Erro ao tentar se conectar com o servidor.");
      });

    Country.all()
      .then((response) => {
        if (response.data.data) this.countries = response.data.data;
      })
      .catch((error) => {
        if (!error.response)
          console.log("Erro ao tentar se conectar com o servidor.");
      });
  },
};
</script>

<style scoped>
label,
span {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

label {
  color: #828282;
}

span {
  color: red;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

button {
  position: absolute;
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  width: max-content;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.card {
  width: 100%;
  max-width: 200px;
  height: 210px;
}

img {
  height: 210px;
}
</style>
