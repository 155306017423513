<template>
  <div>
    <h1 class="mt-5">Período de Formação</h1>
    <table class="table table-bordered mt-3">
      <thead class="thead-orange">
        <tr>
          <th>TIPO</th>
          <th>COMUNIDADE</th>
          <th>LOCAL</th>
          <th>ANO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(period, id) in periods" :key="id">
          <td class="td">{{ toTypeName(period.type) ?? "" }}</td>
          <template v-if="period.type !== '7'">
            <td class="td">{{ toComunityName(period.comunity) }}</td>
            <td class="td">{{ period.location ?? "" }}</td>
            <td class="td">{{ period.year ?? "" }}</td>
            <td class="td d-flex justify-content-evenly">
              <span class="material-icons">
                <a
                  href=""
                  style="color: #00c0c9"
                  @click="edit(id)"
                  data-bs-toggle="modal"
                  data-bs-target="#periodModal">
                  edit
                </a>
              </span>
              <span class="material-icons">
                <a href="" style="color: #cf3900" @click.prevent="remove(id)">
                  delete
                </a>
              </span>
            </td>
          </template>
          <template v-else>
            <td class="td" colspan="3">{{ period.observation ?? "" }}</td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-2">
      <button
        type="button"
        class="btn button"
        data-bs-toggle="modal"
        data-bs-target="#periodModal"
        @click="clear"
      >
        Adicionar Período
      </button>
    </div>
    <div
      class="modal fade"
      id="periodModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="periodModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!isUpdate">Novo Período</h5>
            <h5 class="modal-title" v-else>Atualizar Período</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col">
                <label for="type" class="form-label">Tipo</label>
                <select class="form-select" v-model="period.type">
                  <option value="0">Admissão</option>
                  <option value="1">Aspirando</option>
                  <option value="2">Postulando</option>
                  <option value="3">Noviciado - 1º Ano</option>
                  <option value="4">Noviciado - 2º Ano</option>
                  <option value="5">1ª Profissão</option>
                  <option value="6">Profissão Perpétua</option>
                  <option value="7">Observações</option>
                </select>
              </div>
            </div>
            <div v-if="period.type !== '7'">
              <div class="row mt-2">
                <div class="col">
                  <label for="comunity" class="form-label">Comunidade</label>
                    <select class="form-select" v-model="period.comunity">
                      <option
                        v-for="(comunity, id) in comunities"
                        :key="id"
                        :value="comunity.id"
                      >
                        {{ comunity.attributes.Name }}
                      </option>
                    </select>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="location" class="form-label">Local</label>
                    <input
                      type="text"
                      class="form-control"
                      id="location"
                      v-model="period.location"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label for="year" class="form-label">Ano</label>
                    <input
                      type="number"
                      class="form-control"
                      id="year"
                      v-model="period.year"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mt-2">
                <div class="col">
                  <label for="observation" class="form-label"
                    >Observações</label
                  >
                  <textarea
                    class="form-control"
                    id="observation"
                    rows="5"
                    v-model="period.observation"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn button"
                @click="addPeriod"
                v-if="!isUpdate"
                data-bs-dismiss="modal"
              >
                Adicionar
              </button>
              <button
                type="button"
                class="btn button"
                data-bs-dismiss="modal"
                @click="updatePeriod(currentPeriod)"
                v-else
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Period from "@/services/period";
import Comunity from "@/services/comunity";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import PeriodValidation from "@/validation/Period";

export default {
  name: "Period",
  props: ["trigger"],
  data() {
    return {
      periods: [],
      comunities: [],
      isUpdate: false,
      currentPeriod: null,
      period: {
        id: null,
        type: null,
        comunity: null,
        location: null,
        year: null,
        observation: null,
        profileId: null
      },
    };
  },
  watch: {
    trigger: function() {
      this.savePeriod();
    },
  },
  methods: {
    savePeriod() {
      this.periods.forEach(period => {
        Period.saveOrUpdate(period)
          .then((response) => {
            period.id = response.data.data.id;
          })
          .catch((error) => {
            if (!error.response)
              this.$toast.error("Erro ao tentar se conectar com o servidor.");
            else
              console.log(error);
          });
      });
    },
    addPeriod() {
      if (!CommomValidation.isFieldsAreNull(this.period)) {
        const errors = PeriodValidation.validate(this.period);

        if (errors.length == 0) {
          this.periods.push(JSON.parse(JSON.stringify(this.period)));
          this.clear();
        } else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }
    },
    updatePeriod(id) {
      if (!CommomValidation.isFieldsAreNull(this.period)) {
        const errors = PeriodValidation.validate(this.period);

        if (errors.length == 0) {
          this.periods[id] = JSON.parse(JSON.stringify(this.period));
        }
        else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }

      this.clear();
      this.isUpdate = false;
    },
    edit(id) {
      if (this.periods[id].id != undefined)
        this.period.id = this.periods[id].id;

      this.period.type = this.periods[id].type;
      this.period.comunity = this.periods[id].comunity;
      this.period.location = this.periods[id].location;
      this.period.year = this.periods[id].year;
      this.period.profileId = this.periods[id].profileId;
      this.period.observation = this.periods[id].observation;

      this.isUpdate = true;
      this.currentPeriod = id;
    },
    remove(id) {
      if (this.periods[id].id) {
        Period.remove(this.periods[id].id)
          .then(() => {
            this.periods.splice(id, 1);
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
      } else {
        this.periods.splice(id, 1);
      }
    },
    clear() {
      for (let [item] of Object.entries(this.period)) {
        this.period[item] = null;
      }

      this.isUpdate = false;
      this.getComunities();
    },
    getPeriods() {
      if (sessionStorage.getItem("updateProfileId"))
        Period.findByProfileId(sessionStorage.getItem("updateProfileId"))
          .then((response) => {
            if (response.data.data) {
              response.data.data.forEach(period => {
                this.period.id = period.id;
                this.period.type = period.attributes.Type;
                this.period.comunity = period.attributes.comunity;
                this.period.location = period.attributes.Location;
                this.period.year = period.attributes.Year;
                this.period.profileId = period.attributes.profile;
                this.period.observation = period.attributes.Observation;
                this.periods.push(JSON.parse(JSON.stringify(this.period)));
              });
            }
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
    },
    getComunities() {
      Comunity.all()
        .then((response) => {
          if (response.data.data.length > 0)
            this.comunities = response.data.data;
            this.comunities.sort((a, b) => a.attributes.Name.localeCompare(b.attributes.Name));
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    toComunityName(index) {
      return this.comunities.find(comunity => comunity.id === index)?.attributes.Name;
    },
    toTypeName(index) {
      const type = {
        0: "Admissão",
        1: "Aspirando",
        2: "Postulando",
        3: "Noviciado - 1º Ano",
        4: "Noviciado - 2º Ano",
        5: "1ª Profissão",
        6: "Profissão Perpétua",
        7: "Observações",
      };

      return type[index];
    },
  },
  mounted() {
    this.getPeriods();
    this.getComunities();
  },
};
</script>

<style scoped>
h1,
label,
.td {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 18px;
  line-height: 21px;
  color: #193250;
}

label {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.table {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.thead-orange {
  background: #033F6D;
  border-radius: 6px 6px 0px 0px;
}

th {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.td {
  font-size: 16px;
  line-height: 18px;
  color: #193250;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #f8af2f;
}

textarea {
  resize: none;
}

a {
  text-decoration: none;
}

#add-comunity {
  text-decoration: underline;
  font-size: 12px;
}
</style>
