<template>
  <div>
    <h1 class="mt-5">Histórico Religioso</h1>
    <table class="table table-bordered mt-3">
      <thead class="thead-orange">
        <tr>
          <th>CIDADE</th>
          <th>COMUNIDADE</th>
          <th>INICIO PERMANÊNCIA</th>
          <th>FIM PERMANÊNCIA</th>
          <th>OCUPAÇÃO</th>
          <th>OBSERVAÇÕES</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(historic, id) in historics" :key="id">
          <td class="td">{{ toCityName(historic.city) ?? "" }}</td>
          <td class="td">{{ toComunityName(historic.city) ?? "" }}</td>
          <td class="td">{{ toDateFormatBR(historic.begin) ?? "" }}</td>
          <td class="td">{{ toDateFormatBR(historic.end) ?? "" }}</td>
          <td class="td">{{ historic.occupation ?? "" }}</td>
          <td class="td">{{ historic.observation ?? "" }}</td>
          <td class="td d-flex justify-content-evenly">
            <span class="material-icons">
              <a
                href=""
                style="color: #00c0c9"
                @click="edit(id)"
                data-bs-toggle="modal"
                data-bs-target="#historicModal">
                edit
              </a>
            </span>
            <span class="material-icons">
              <a href="" style="color: #cf3900" @click.prevent="remove(id)">
                delete
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-2">
      <button
        type="button"
        class="btn button"
        data-bs-toggle="modal"
        data-bs-target="#historicModal"
        @click="clear"
      >
        Adicionar Histórico
      </button>
    </div>
    <div
      class="modal fade"
      id="historicModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="historicModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!isUpdate">Novo Histórico</h5>
            <h5 class="modal-title" v-else>Atualizar Histórico</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col">
                <label for="city" class="form-label">Estado</label>
                <select class="form-select" v-model="historic.state" @change="getCitiesByState">
                  <option v-for="(state, id) in states" :key="id" :value="state.id">
                    {{ state.attributes.Abbreviation }}
                  </option>
                </select>
                <!-- <select class="form-select" v-model="historic.state">
                  <option
                    v-for="(state, id) in states"
                    :key="id"
                    :value="city.id"
                  >
                    {{ city.attributes.Name }}
                  </option>
                </select> -->
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <label for="city" class="form-label">Cidade</label>
                <select class="form-select" v-model="historic.city">
                  <option
                    v-for="(city, id) in cities"
                    :key="id"
                    :value="city.id"
                  >
                    {{ city.attributes.Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <label for="comunity" class="form-label">Comunidade</label>
                <select class="form-select" v-model="historic.comunities">
                  <option
                    v-for="(comunity, id) in comunities"
                    :key="id"
                    :value="comunity.id"
                  >
                    {{ comunity.attributes.Name }}
                  </option>
                </select>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="begin" class="form-label"
                    >Inicio Permanência</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="begin"
                    v-model="historic.begin"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="end" class="form-label">Fim Permanência</label>
                  <input
                    type="date"
                    class="form-control"
                    id="end"
                    v-model="historic.end"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="occupation" class="form-label">Ocupação</label>
                  <input
                    type="text"
                    class="form-control"
                    id="occupation"
                    maxlength="300"
                    v-model="historic.occupation"
                  />
                </div>
                <div class="maxChar">Max: 300 caracteres</div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label for="obervation" class="form-label">Observação</label>
                  <input
                    type="text"
                    class="form-control"
                    id="obervation"
                    maxlength="300"
                    placeholder="Ex: Coordenador"
                    v-model="historic.observation"
                  />
                </div>
                <div class="maxChar">Max: 300 caracteres</div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn button"
                @click="addHistoric"
                v-if="!isUpdate"
                data-bs-dismiss="modal"
              >
                Adicionar
              </button>
              <button
                type="button"
                class="btn button"
                data-bs-dismiss="modal"
                @click="updateHistoric(currentHistoric)"
                v-else
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Historic from "@/services/historic";
import City from "@/services/city";
import Comunity from "@/services/comunity";
import Zip from "@/services/zipcode";
import State from "@/services/state";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import HistoricValidation from "@/validation/Historic";

export default {
  name: "Historic",
  props: ["trigger"],
  data() {
    return {
      historics: [],
      cities: [],
      states: [],
      comunities: [],
      isUpdate: false,
      currentHistoric: null,
      historic: {
        id: null,
        begin: null,
        end: null,
        occupation: null,
        observation: null,
        city: null,
        state: null,
        comunity: null,
        profileId: null,
        zip:null
      },
    };
  },
  watch: {
    trigger: function() {
        this.saveHistoric();
    },
  },
  methods: {
    getZipCode(event) {
      if (event.target.value.length == 9)
      {
        Zip.getZipCodeAddress(event.target.value)
          .then((response) => {
            this.historic.city = response.data.localidade;
            this.historic.state = response.data.uf;
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se comunicar com API viacep.");
          });
      }
    },
    saveHistoric() {
      this.historics.forEach(historic => {
        Historic.saveOrUpdate(historic)
          .then((response) => {
            historic.id = response.data.data.id;
          })
          .catch((error) => {
            if (!error.response)
              this.$toast.error("Erro ao tentar se conectar com o servidor.");
            else
              console.log(error);
          });
      });
    },
    addHistoric() {
      if (!CommomValidation.isFieldsAreNull(this.historic)) {
        const errors = HistoricValidation.validate(this.historic);

        if (errors.length == 0) {
          this.historics.push(JSON.parse(JSON.stringify(this.historic)));
          this.clear();
        } else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }
    },
    updateHistoric(id) {
      if (!CommomValidation.isFieldsAreNull(this.historic)) {
        const errors = HistoricValidation.validate(this.historic);

        if (errors.length == 0) {
          this.historics[id] = JSON.parse(JSON.stringify(this.historic));
        }
        else {
          errors.forEach((error) => {
            this.$toast.error(error);
          });
        }
      }

      this.clear();
      this.isUpdate = false;
    },
    edit(id) {
      if (this.historics[id].id != undefined)
        this.historic.id = this.historics[id].id;

      this.historic.begin = this.historics[id].begin;
      this.historic.end = this.historics[id].end;
      this.historic.occupation = this.historics[id].occupation;
      this.historic.observation = this.historics[id].observation;
      this.historic.city = this.historics[id].city;
      this.historic.comunity = this.historics[id].comunity;
      this.historic.profileId = this.historics[id].profileId;

      this.isUpdate = true;
      this.currentHistoric = id;
    },
    remove(id) {
      if (this.historics[id].id) {
        Historic.remove(this.historics[id].id)
          .then(() => {
            this.historics.splice(id, 1);
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
      } else {
        this.historics.splice(id, 1);
      }
    },
    clear() {
      for (let [item] of Object.entries(this.historic)) {
        this.historic[item] = null;
      }

      this.isUpdate = false;
      this.getComunities();
    },
    getHistoric() {
      if (sessionStorage.getItem("updateProfileId"))
        Historic.findByProfileId(sessionStorage.getItem("updateProfileId"))
          .then((response) => {
            if (response.data.data)
              response.data.data.forEach(historic => {
                this.historic.id = historic.id;
                this.historic.begin = historic.attributes.Begin;
                this.historic.end = historic.attributes.End;
                this.historic.occupation = historic.attributes.Occupation;
                this.historic.city = historic.attributes.city.data?.id;
                this.historic.observation = historic.attributes.Observation;
                this.historic.comunity = historic.attributes.comunity.data?.id;
                this.historics.push(JSON.parse(JSON.stringify(this.historic)));
              });
          })
          .catch((error) => {
            if (!error.response)
              console.log("Erro ao tentar se conectar com o servidor.");
          });
    },
    getCities() {
      City.all()
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getStates() {
      City.all()
        .then((response) => {
          if (response.data.data) this.states = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    getComunities() {
      Comunity.all()
        .then((response) => {
          if (response.data.data)
            this.comunities = response.data.data;
            this.comunities.sort((a, b) => a.attributes.Name.localeCompare(b.attributes.Name));
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    toCityName(cityId) {
      return this.cities.find((city) => city.id === cityId)
        ?.attributes.Name;
    },
    toComunityName(comunityId) {
      return this.comunities.find(
        (comunity) => comunity.id === comunityId
      )?.attributes.Name;
    },
    toDateFormatBR(date) {
      if (date) {
        var data = new Date(date);
        return data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
      }
    },
    getCitiesByState(event) {
      City.getCitiesByState(event.target.value)
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
  },
  mounted() {
    this.getCities();
    this.getComunities();
    this.getHistoric();

    State.all()
      .then((response) => {
        if (response.data.data) this.states = response.data.data;
      })
      .catch((error) => {
        if (!error.response)
          console.log("Erro ao tentar se conectar com o servidor.");
      });
  },
};
</script>

<style scoped>
h1,
label,
.td {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 18px;
  line-height: 21px;
  color: #193250;
}

label {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.table {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.thead-orange {
  background: #033F6D;
  border-radius: 6px 6px 0px 0px;
}

th {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.td {
  font-size: 16px;
  line-height: 18px;
  color: #193250;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #f8af2f;
}

.maxChar {
  font-size: 10px;
  text-align: right;
}

a {
  text-decoration: none;
}
</style>
