import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
  findByProfileId: (profileId) => {
    return HTTP.get(`galleries/byprofile/${profileId}`, {
      headers: {
        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
      }
    });
  },
  remove: (id) => {
    return HTTP.delete(`galleries/${id}`, {
      headers: {
        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
      }
    });
  },
  saveOrUpdate: (gallery) => {
    const data = {
      profile: sessionStorage.getItem("updateProfileId"),
    }

    if (!gallery.id)
      return HTTP.post("galleries", { data }, {
        headers: {
          Authorization: `Bearer ${userStore.getters.getUser.jwt}`
        }
      });

    return HTTP.put(`galleries/${gallery.id}`, { data }, {
      headers: {
        Authorization: `Bearer ${userStore.getters.getUser.jwt}`
      }
    });
  }
}
