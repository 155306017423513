<template>
  <form class="g-3" v-on:submit.prevent>
    <div class="row mt-3">
      <div class="col-md-4">
        <label for="rg" class="form-label">RG</label>
        <input type="text" class="form-control" id="rg" v-model="document.rg" />
      </div>
      <div class="col-md-3">
        <label for="issue" class="form-label">Data de Emissão</label>
        <input
          type="date"
          class="form-control"
          id="issue"
          v-model="document.issue"
        />
      </div>
      <div class="col-md-2">
        <label for="issuer" class="form-label">Orgão Emissor</label>
        <select class="form-select" v-model="document.issuer">
          <option v-for="(state, id) in states" :key="id" :value="state.id">
            {{ state.attributes.Abbreviation }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="cpf" class="form-label">CPF</label>
        <input
          type="text"
          class="form-control"
          id="cpf"
          v-mask="'###.###.###-##'"
          v-model="document.cpf"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label for="votecard" class="form-label">Título de Eleitor</label>
        <input
          type="text"
          class="form-control"
          id="votecard"
          v-model="document.voteCard"
        />
      </div>
      <div class="col-md-2">
        <label for="zone" class="form-label">Zona</label>
        <input
          type="text"
          class="form-control"
          id="zone"
          v-model="document.zone"
        />
      </div>
      <div class="col-md-2">
        <label for="section" class="form-label">Seção</label>
        <input
          type="text"
          class="form-control"
          id="section"
          v-model="document.section"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <label for="socialsecurity" class="form-label"
          >Previdência Social</label
        >
        <input
          type="text"
          class="form-control"
          id="socialsecurity"
          v-model="document.socialSecurity"
        />
      </div>
      <div class="col-md-3">
        <label for="pis" class="form-label">PIS</label>
        <input
          type="text"
          class="form-control"
          id="pis"
          v-model="document.pis"
        />
      </div>
      <div class="col-md-3">
        <label for="pisdate" class="form-label">Data PIS</label>
        <input
          type="date"
          class="form-control"
          id="pisdate"
          v-model="document.pisDate"
        />
      </div>
      <div class="col-md-2">
        <label for="agencycode" class="form-label">Código Agência</label>
        <input
          type="text"
          class="form-control"
          id="agencycode"
          v-model="document.agencyCode"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <label for="agencystreet" class="form-label">Endereço Agência</label>
        <input
          type="text"
          class="form-control"
          id="agencystreet"
          v-model="document.agencyStreet"
        />
      </div>
      <div class="col-md-1">
        <label for="number" class="form-label">Número</label>
        <input
          type="text"
          class="form-control"
          id="number"
          v-model="document.number"
        />
      </div>
      <div class="col-md-3">
        <label for="district" class="form-label">Bairro</label>
        <input
          type="text"
          class="form-control"
          id="district"
          v-model="document.district"
        />
      </div>
      <div class="col-md-1">
        <label for="state" class="form-label">Estado</label>
        <select class="form-select" v-model="document.state" @change="getCitiesByState">
          <option v-for="(state, id) in states" :key="id" :value="state.id">
            {{ state.attributes.Abbreviation }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="city" class="form-label">Cidade</label>
        <select class="form-select" v-model="document.city">
          <option v-for="(city, id) in cities" :key="id" :value="city.id">
            {{ city.attributes.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-3">
        <label for="passport" class="form-label">Passaporte</label>
        <input
          type="text"
          class="form-control"
          id="passport"
          v-model="document.passport"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Document from "@/services/documents";
import City from "@/services/city";
import State from "@/services/state";

/** Validations */
import CommomValidation from "@/validation/CommomValidation";
import DocumentValidation from "@/validation/Document";

export default {
  name: "Documents",
  props: ["validateTrigger", "saveTrigger"],
  data() {
    return {
      cities: [],
      states: [],
      document: {
        id: null,
        rg: null,
        issue: null,
        issuer: null,
        cpf: null,
        voteCard: null,
        zone: null,
        section: null,
        socialSecurity: null,
        pis: null,
        pisDate: null,
        agencyCode: null,
        agencyStreet: null,
        number: null,
        district: null,
        city: null,
        state: null,
        passport: null,
        profile: null
      },
    };
  },
  watch: {
    validateTrigger: function() {
        this.validate();
    },
    saveTrigger: function() {
        this.saveDocument();
    }
  },
  methods: {
    validate() {
      const errors = DocumentValidation.validate(this.document);
      this.$emit("errors", errors);
    },
    saveDocument() {
      if (!CommomValidation.isFieldsAreNull(this.document)) {
        Document.saveOrUpdate(this.document)
          .then((response) => {
            console.log(response.data.data)
            this.document.id = response.data.data.id;
          })
          .catch((error) => {
            if (!error.response)
              this.$toast.error("Erro ao tentar se conectar com o servidor.");
            else
              console.log('ERRRRRR',error);
          });
      }
    },
    getCitiesByState(event) {
      City.getCitiesByState(event.target.value)
        .then((response) => {
          if (response.data.data) this.cities = response.data.data;
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });
    },
    clear() {
      for (let [item] of Object.entries(this.document)) {
        this.document[item] = null;
      }
    },
  },
  mounted() {
    if (sessionStorage.getItem("updateProfileId"))
      Document.findOne(sessionStorage.getItem("updateProfileId"))
        .then((response) => {
          if (response.data.data) {
            this.document.id = response.data.data.id;
            this.document.rg = response.data.data.attributes.Rg;
            this.document.issue = response.data.data.attributes.Issue;
            this.document.issuer = response.data.data.attributes.issuer.data?.id;
            this.document.cpf = response.data.data.attributes.Cpf;
            this.document.voteCard = response.data.data.attributes.VoteCard;
            this.document.zone = response.data.data.attributes.Zone;
            this.document.section = response.data.data.attributes.Section;
            this.document.socialSecurity = response.data.data.attributes.SocialSecurity;
            this.document.pis = response.data.data.attributes.Pis;
            this.document.pisDate = response.data.data.attributes.PisDate;
            this.document.agencyCode = response.data.data.attributes.AgencyCode;
            this.document.agencyStreet = response.data.data.attributes.AgencyStreet;
            this.document.number = response.data.data.attributes.Number;
            this.document.district = response.data.data.attributes.District;
            this.document.city = response.data.data.attributes.city.data?.id;
            this.document.state = response.data.data.attributes.state.data?.id;
            this.document.passport = response.data.data.attributes.Passport;
          }
        })
        .catch((error) => {
          if (!error.response)
            console.log("Erro ao tentar se conectar com o servidor.");
        });

    State.all()
      .then((response) => {
        if (response.data.data) this.states = response.data.data;
      })
      .catch((error) => {
        if (!error.response)
          console.log("Erro ao tentar se conectar com o servidor.");
      });
  },
};
</script>

<style scoped>
label,
span {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

label {
  color: #828282;
}

span {
  color: red;
}

input[type="text"] {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}
</style>
