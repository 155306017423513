export default {
    validate: (period) => {
        let errors = [];

        if (!period.type)
            errors.push("Escolha um tipo");
        
        if (period.street && (period.street.length > 50 || period.street.length < 3))
            errors.push("Endereço deve ter entre 3 e 50 caracteres");
        
        if (period.location && (period.location.length > 50))
            errors.push("Local deve ter no máximo 50 caracteres");

        if (period.year) {
            const regex = new RegExp("[0-9]{4}");

            if (!regex.test(period.year) || period.year < (new Date().getFullYear() - 100))
                errors.push("Ano inválido");
        }

        return errors;
    }
}