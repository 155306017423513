export default {
    validate: (profile) => {
        const errors = [];

        if (!profile.firstName)
            errors.push("Nome é obrigatório");

        if (!profile.lastName)
            errors.push("Sobrenome é obrigatório");
    
        if (!profile.birthdate)
            errors.push("Data de Nascimento é obrigatório");

        if (!profile.country)
            errors.push("Nacionalidade é obrigatório");

        if (profile.firstName && (profile.firstName.length > 20 || profile.firstName.length < 2))
            errors.push("Nome deve ter entre 2 e 20 caracteres");
        
        if (profile.lastName && (profile.lastName.length > 50 || profile.lastName.length < 2))
            errors.push("Sobrenome deve ter entre 2 e 50 caracteres");
        
        if (profile.nickName && (profile.nickName.length > 50 || profile.nickName.length < 2))
            errors.push("Nome Religioso deve ter entre 2 e 50 caracteres");

        if (profile.email && (profile.email.length > 100))
            errors.push("Email deve ter até 100 caracteres");

        if (profile.fatherName && (profile.fatherName.length > 50 || profile.fatherName.length < 2))
            errors.push("Nome do Pai deve ter entre 2 e 50 caracteres");

        if (profile.motherName && (profile.motherName.length > 50 || profile.motherName.length < 2))
            errors.push("Nome do Pai deve ter entre 2 e 50 caracteres");

        return errors;
    }
}