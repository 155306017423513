<template>
  <div class="album py-3 album-bg">
    <p>Escolha as fotos para a galeria da irmã cadastrada</p>
    <input
      style="display: none"
      type="file"
      class="mb-3"
      @change="onFileSelected"
      ref="fileInput"
      multiple
    />
    <button
      @click="$refs.fileInput.click()"
      class="btn px-3 d-flex justify-content-around"
    >
      <span class="material-icons-outlined me-3">image</span>
      Adicionar foto
    </button>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mt-2">
      <div class="col-md-3" v-for="image in images" :key="image.id">
        <img :src="image" class="img-thumbnail">
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from "@/services/gallery";
import Image from "@/services/upload";

export default {
  name: "Gallery",
  props: ["saveTrigger"],
  data() {
    return {
      images: [],
      selectedFiles: [],
      gallery: {
        id: null,
        image: null,
        profileId: null,
      },
    };
  },
  watch: {
    saveTrigger: function() {
      this.saveGallery();
    }
  },
  methods: {
    getGallery() {
      Gallery.findByProfileId(sessionStorage.getItem("updateProfileId"))
        .then((response) => {
          if (response.data.data) {
            this.gallery.id = response.data.data[0].id;
            const hasImages = response.data.data[0].attributes.Images.data;
            
            if (hasImages) {
              hasImages.forEach(image => {
                const imgUrl = `https://api.associadas.dominicanas.org.br${image.attributes.formats.thumbnail.url}`
                this.images.push(imgUrl);
              });
            }
          }
        })
        .catch((error) => {
          console.log("Erro ao tentar se recuperar Gallery: " + error);
        });
    },
    onFileSelected(event) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFiles.push(event.target.files.item(i));
        this.images.push(URL.createObjectURL(event.target.files.item(i)));
      }
    },
    saveGallery() {
      Gallery.saveOrUpdate(this.gallery)
        .then((response) => {
          this.gallery.id = response.data.data.id;
          this.uploadFile();
        })
        .catch((error) => {
          console.log(`Erro ao salvar gallery ${error}`);
        });
    },
    uploadFile() {
      if (this.selectedFiles.length > 0) {
        this.selectedFiles.forEach(file => {
          const form = new FormData();
          form.append("files", file);
          form.append("ref", "api::gallery.gallery");
          form.append("refId", this.gallery.id);
          form.append("field", "Images");

          Image.upload(form)
            .catch((error) => {
                console.log(error);
            });
        });
      }
    },
  },
  mounted() {
    if (sessionStorage.getItem("updateProfileId"))
      this.getGallery();
  },
};
</script>

<style scoped>
img:hover {
  border: 2px solid blue;
}
.album-bg{
  margin: 0;
}

p {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #b2b2b4;
}

button {
  background: #ffffff;
  margin: 20px;
  border: 1px solid #033F6D;
  border-radius: 6px;
}

.img-thumbnail {
  height: 200px;
}
</style>
