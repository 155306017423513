export default {
    validate: (historic) => {
        let errors = [];

        if (historic.occupation && (historic.occupation.length > 20))
            errors.push("Ocupação deve ter no máximo 20 caracteres");
        
        if (historic.observation && (historic.observation.length > 100))
            errors.push("Observação deve ter no máximo 100 caracteres");

        return errors;
    }
}